import { createSlice } from '@reduxjs/toolkit';
// khai báo  initialState
const initialState = {
	currentCategory: 'All',
	products: [],
	productCart: [],
	quantity: 0,
};

// tạo slice product
const productSlice = createSlice({
	name: 'product-category',
	initialState,
	reducers: {
		setCategory(state, action) {
			state.currentCategory = action.payload;
		},
		setProduct(state, action) {
			state.products = action.payload;
		},
		productQuantity(state, action) {
			state.productCart = action.payload.product;
			state.quantity = action.payload.quantity;
			console.log(state.productCart);
		},
		decremenHandler(state, action) {
			const product = action.payload.product;
			if (product) {
				product[0].quantity -= 1;
				state.productCart = product;
			}
		},
		incremenHandler(state, action) {
			const product = action.payload.product;
			if (product) {
				product[0].quantity += 1;
				state.productCart = product;
			}
		},
		removeItem(state, action) {
			state.productCart = action.payload;
		},
	},
});

export const productAction = productSlice.actions;
export default productSlice.reducer;
