import React, { useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './SignupPage.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import validator from 'validator';
const cx = classNames.bind(styles);

const SignupPage = () => {
	const nameRef = useRef();
	const emailRef = useRef();
	const passRef = useRef();
	const phoneRef = useRef();
	const [errors, setErrors] = useState('');
	let newUser = {};

	const navigate = useNavigate();
	const existingAccounts = JSON.parse(localStorage.getItem('userArr')) || [];
	// validate cho form đăng ký
	const valiDateForm = () => {
		const nameValue = nameRef.current.value.trim();
		const emailValue = emailRef.current.value.trim();
		const passValue = passRef.current.value.trim();
		const phoneValue = phoneRef.current.value.trim();

		const isValidPhone = (phoneValue) => {
			const clearnPhone = phoneValue.replace(/\D/g, '');
			return clearnPhone.length === 10;
		};
		newUser = {
			email: emailValue,
			password: passValue,
			name: nameValue,
			phoneNumber: phoneValue,
		};
		let newError = {};
		if (nameValue === '') {
			newError.nameValue = 'Full name is required';
		}
		if (existingAccounts.some((item) => item.email === emailValue)) {
			newError.emailValue = 'Email already exists';
		}
		if (!validator.isEmail(emailValue)) {
			newError.emailValue = 'Email is valid';
		}

		if (passValue.length < 8) {
			newError.passValue = 'Please enter password over 7 characters';
		}
		if (!isValidPhone(phoneValue)) {
			newError.phoneValue = 'Phone number is valid';
		}
		setErrors(newError);
		return Object.keys(newError).length === 0;
	};
	const handlerSignUP = () => {
		if (valiDateForm()) {
			const updatedAccounts = [...existingAccounts, newUser];

			localStorage.setItem('userArr', JSON.stringify(updatedAccounts));
			navigate('/sign-in');
		}
	};
	return (
		<>
			<div className={cx('container')}>
				<div className={cx('container-form')}>
					<h3>Sign Up</h3>
					<div className={cx('form')}>
						<input ref={nameRef} placeholder='Full Name' />
						{errors.nameValue && (
							<p style={{ color: 'red' }}>{errors.nameValue}</p>
						)}
						<br />
						<input ref={emailRef} placeholder='Email' type='email' />
						{errors.emailValue && (
							<p style={{ color: 'red' }}>{errors.emailValue}</p>
						)}

						<br />
						<input ref={passRef} placeholder='Password' />
						{errors.passValue && (
							<p style={{ color: 'red' }}>{errors.passValue}</p>
						)}

						<br />
						<input ref={phoneRef} placeholder='Phone' />
						{errors.phoneNumber && (
							<p style={{ color: 'red' }}>{errors.phoneNumber}</p>
						)}
						<button onClick={handlerSignUP}>Sign Up</button>
						<p>
							Sign In? <Link to='/sign-in'>Click</Link>
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default SignupPage;
