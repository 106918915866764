import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './CartPage.module.scss';
import { useDispatch, useSelector } from 'react-redux';

import { productAction } from '../store/productSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faArrowLeftLong,
	faArrowRightLong,
	faCaretLeft,
	faCaretRight,
	faGift,
	faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);

const CartPage = () => {
	const dispatch = useDispatch();
	const productcart = useSelector((state) => state.product.productCart);
	const [cartItems, setCartItems] = useState([]);
	console.log(productcart);
	useEffect(() => {
		// Lấy dữ liệu từ local storage và chuyển thành mảng
		const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
		setCartItems(storedCartItems);
	}, [productcart]);
	// hàm sử lý tăng giảm quantity và update cartItems
	const handlerDecremen = (id) => {
		const product = cartItems.filter((item) => item.product._id.$oid === id);
		const items = {
			product,
			id,
		};
		dispatch(productAction.decremenHandler(items));
		const updatedCartItems =
			JSON.parse(localStorage.getItem('cartItems')) || [];
		const updatedProduct = updatedCartItems.find(
			(item) => item.product._id.$oid === id
		);
		if (updatedProduct) {
			if (updatedProduct.quantity > 1) {
				updatedProduct.quantity -= 1;
				localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
			}
		}
	};
	const handlerIncremen = (id) => {
		const product = cartItems.filter((item) => item.product._id.$oid === id);
		const items = {
			product,
			id,
		};
		dispatch(productAction.incremenHandler(items));
		const updatedCartItems =
			JSON.parse(localStorage.getItem('cartItems')) || [];
		const updatedProduct = updatedCartItems.find(
			(item) => item.product._id.$oid === id
		);
		if (updatedProduct) {
			updatedProduct.quantity += 1;
			localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
		}
	};
	// xóa sản phẩm khỏi cart
	const handlerRemove = (id) => {
		const upDateCartItem = cartItems.filter(
			(fil) => fil.product._id.$oid !== id
		);
		dispatch(productAction.removeItem(upDateCartItem));
		localStorage.setItem('cartItems', JSON.stringify(upDateCartItem));
		setCartItems(upDateCartItem);
	};

	// console.log(cartItems);
	let total;
	let totalSub = 0;
	return (
		<>
			<div className={cx('container')}>
				<div className={cx('banner')}>
					<p>CART</p>
					<p>CART</p>
				</div>
				<div className={cx('shoping-cart')}>
					<h4>SHOPING CART</h4>
					<div className={cx('cart')}>
						<div className={cx('cart-content')}>
							<div className={cx('title')}>
								<p>IMAGE</p>
								<p>PRODUCT</p>
								<p>PRICE</p>
								<p>QUANTITY</p>
								<p>TOTAL</p>
								<p>REMOVE</p>
							</div>

							{cartItems.map((item, index) => {
								{
									total = item.quantity * item.product.price;
								}
								{
									totalSub += total;
								}
								return (
									<div key={index} className={cx('cart-product')}>
										<img src={item.product.img1} />
										<p className={cx('name')}>{item.product.name}</p>
										<p className={cx('price')}>
											{item.product.price
												.toString()
												.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
											VND
										</p>
										<div className={cx('quantity')}>
											<FontAwesomeIcon
												onClick={() => handlerDecremen(item.product._id.$oid)}
												icon={faCaretLeft}
											/>
											<p>{item.quantity}</p>
											<FontAwesomeIcon
												onClick={() => handlerIncremen(item.product._id.$oid)}
												icon={faCaretRight}
											/>
										</div>
										<p className={cx('total')}>
											{total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
											VND
										</p>
										<p className={cx('remove')}>
											<FontAwesomeIcon
												className={cx('icon')}
												icon={faTrashCan}
												onClick={() => {
													handlerRemove(item.product._id.$oid);
												}}
											/>
										</p>
									</div>
								);
							})}
						</div>
						<div className={cx('cart-total')}>
							<h4>CART TOTAL</h4>
							<div className={cx('sub-total')}>
								<h6>SUB TOTAL</h6>
								<p>
									{totalSub.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}{' '}
									VND
								</p>
							</div>
							<div className={cx('totals')}>
								<h6>TOTAL</h6>
								<p>
									{totalSub.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}{' '}
									VND
								</p>
							</div>
							<div className={cx('input')}>
								<input placeholder='Enter your Coupon' />
								<button>
									<FontAwesomeIcon className={cx('icon')} icon={faGift} /> Apply
									Coupon
								</button>
							</div>
						</div>
						<div className={cx('navigation')}>
							<Link className={cx('navigation-icon')} to='/shop'>
								<FontAwesomeIcon
									className={cx('icon')}
									icon={faArrowLeftLong}
								/>
								<p>Continue Shopping</p>
							</Link>
							<Link to='/checkout' className={cx('navigation-icon')}>
								<p>Proceed to checkout</p>
								<FontAwesomeIcon
									className={cx('icon')}
									icon={faArrowRightLong}
								/>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CartPage;
