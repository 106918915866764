import React from 'react';
import Banner from './Banner';
import Product from './Product';
import ListProduct from './ListProduct';
import Subscribe from './Subscribe';
import Footer from '../layout/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCommentDots,
	faFaceSmile,
	faPaperPlane,
	faPaperclip,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames/bind';
import styles from './Home.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { popupAction } from '../store/showHideSlice';
import Modal from '../Modal';

const cx = classNames.bind(styles);
export const Home = () => {
	const isShowFormMess = useSelector((state) => state.popup.isShowForm);
	console.log(isShowFormMess);
	const dispatch = useDispatch();
	const handlerShowForm = () => {
		dispatch(popupAction.showFormMess());
	};
	return (
		<>
			<div className={cx('container')}>
				<Banner />
				<Product />
				<ListProduct />
				<Subscribe />
				<Footer />
				{/* call comp modal */}
				{isShowFormMess && (
					<Modal isShowMess={isShowFormMess}>
						<div className={cx('modal-content')}>
							<div className={cx('title-modal')}>
								<h6>Customer Support</h6>
								<button>Les't Chat App</button>
							</div>
							<div className={cx('content')}>
								<div className={cx('text-user')}>
									<p>Xin chào</p>
									<p>Làm thế nào để xem các sản phẩm?</p>
								</div>
								<div className={cx('text-admin')}>
									<p>
										<img src='/assets/images/42394608_2179729705686535_2045392550251986944_n (1).jpg' />
										ADMIN: Chào bạn!
									</p>
									<p>
										<img src='/assets/images/42394608_2179729705686535_2045392550251986944_n (1).jpg' />
										ADMIN: Bạn có thể vào mục shop để xem các sản phẩm
									</p>
								</div>
							</div>
							<div className={cx('content-footer')}>
								<div className={cx('avatar')}>
									<img src='/assets/images/42394608_2179729705686535_2045392550251986944_n (1).jpg' />
								</div>
								<input placeholder='Enter Message!' />
								<div className={cx('footer-icon')}>
									<FontAwesomeIcon className={cx('icon')} icon={faPaperclip} />
									<FontAwesomeIcon className={cx('icon')} icon={faFaceSmile} />
									<FontAwesomeIcon className={cx('icon')} icon={faPaperPlane} />
								</div>
							</div>
						</div>
					</Modal>
				)}
				<div onClick={handlerShowForm} className={cx('message')}>
					<img
						className={cx(`${isShowFormMess ? 'active' : 'img'}`)}
						src='/assets/images/tải xuống.png'
					/>
				</div>
			</div>
		</>
	);
};
