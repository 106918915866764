import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isLogin: false,
	user: null,
};

const loginSlicce = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		onLogin(state, actions) {
			state.isLogin = true;
			state.user = actions.payload;

			console.log(actions.payload);
		},
		onLogout(state) {
			state.isLogin = false;
			state.user = null;
		},
	},
});

export const loginAction = loginSlicce.actions;
export default loginSlicce.reducer;
