import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames/bind';
import styles from './DetailPage.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import RelatedProducts from './RelatedProducts';
import { productAction } from '../store/productSlice';

const cx = classNames.bind(styles);
const DetailPage = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [inputValue, setInputValue] = useState(1);
	const products = useSelector((state) => state.product.products);
	const currentCategory = useSelector((state) => state.product.currentCategory);

	const productData = JSON.parse(localStorage.getItem('productData')) || [];

	const product = productData.find((product) => product._id.$oid === id);

	const relatedProducts =
		currentCategory === 'All'
			? products
			: products.filter((fil) => fil.category === currentCategory);

	const changeInput = (e) => {
		setInputValue(parseInt(e.target.value));
	};
	const handlerDecremen = () => {
		if (inputValue > 1) {
			setInputValue(inputValue - 1);
		}
	};
	const handlerIncremen = () => {
		setInputValue(inputValue + 1);
	};
	// sử lý khi clik nút add cart
	const handlerAddCart = () => {
		const items = {
			product,
			quantity: inputValue,
		};
		dispatch(productAction.productQuantity(items));
		const existingCartItems =
			JSON.parse(localStorage.getItem('cartItems')) || [];
		const updateCartItem = [...existingCartItems, items];
		console.log(existingCartItems);
		localStorage.setItem('cartItems', JSON.stringify(updateCartItem));
	};

	console.log(product);
	console.log(products);
	return (
		<>
			<div className={cx('container')}>
				<div className={cx('container-product')}>
					<div className={cx('image')}>
						<img alt='product image' src={product.img1} />
					</div>
					<div className={cx('text')}>
						<h4>{product.name}</h4>
						<p className={cx('price')}>
							{product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}{' '}
							VND
						</p>
						<p className={cx('long-dec')}>{product.short_desc}</p>
						<div className={cx('dflex')}>
							CATEGORY: <p>{product.category}</p>
						</div>
						<div className={cx('container-btn')}>
							<input
								type='number'
								placeholder='QUANTITY'
								onChange={changeInput}
								value={inputValue}
							/>
							<div className={cx('flex-icon')}>
								<FontAwesomeIcon onClick={handlerDecremen} icon={faCaretLeft} />
								<p>{inputValue}</p>
								<FontAwesomeIcon
									onClick={handlerIncremen}
									icon={faCaretRight}
								/>
							</div>
							<button onClick={() => handlerAddCart()}>Add to cart</button>
						</div>
					</div>
				</div>
				<div className={cx('related-product')}>
					<button>DESCRIPTION</button>
					<p className={cx('related-long_desc')}>{product.long_desc}</p>

					<div className={cx('container-related')}>
						<p className={cx('related-title')}>RELATED PRODUCTS</p>
						<div className={cx('related')}>
							{relatedProducts.map((item, index) => {
								return <RelatedProducts item={item} key={index} />;
							})}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DetailPage;
