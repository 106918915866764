import React, { memo, useEffect, useState } from 'react';
import classes from './Navbar.module.css';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCartFlatbed,
	faSortDown,
	faUser,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { loginAction } from '../store/loginUserSlice';

const Navbar = () => {
	const dispatch = useDispatch();
	const products = useSelector((state) => state.product.products);
	const productCart = useSelector((state) => state.product.productCart);
	const isLogin = useSelector((state) => state.auth.isLogin);
	const user = useSelector((state) => state.auth.user);
	const [cartItem, setCartItems] = useState([]);
	useEffect(() => {
		// Lấy dữ liệu từ local storage và chuyển thành mảng
		const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
		setCartItems(storedCartItems);
	}, [products, productCart]);
	useEffect(() => {
		const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));
		if (loggedInUser) {
			dispatch(loginAction.onLogin(loggedInUser));
		}
	}, [dispatch]);
	const lengthCart = cartItem.length;
	const handlerLogout = () => {
		dispatch(loginAction.onLogout());
		localStorage.removeItem('loggedInUser');
	};

	const classlogin = user ? classes.flexCartUser : classes.flexCart;
	const classloginUser = user ? classes.loginWidth : classes.login;
	const classloginCart = user ? classes.cartLogin : classes.cart;

	return (
		<div className={classes.navbar}>
			<div className={classes.flex}>
				<NavLink
					to='/'
					className={({ isActive }) =>
						isActive ? classes.active : classes.nav
					}
				>
					<p className={classes.text}>Home</p>
				</NavLink>

				<NavLink
					to='/shop'
					className={({ isActive }) =>
						isActive ? classes.active : classes.nav
					}
				>
					<p>Shop</p>
				</NavLink>
			</div>

			<div className={classes.flexBoutique}>
				<p className={classes.boutique}>BOUTIQUE</p>
			</div>
			<div className={classlogin}>
				<div className={classloginCart}>
					<NavLink
						to='cart'
						className={({ isActive }) =>
							isActive ? classes.active : classes.nav
						}
					>
						<p className={classes.cartnumber}>{lengthCart}</p>
						<div className={classes.flexIcon}>
							<FontAwesomeIcon icon={faCartFlatbed} className={classes.icon} />
							<p>Cart</p>
						</div>
					</NavLink>
				</div>
				<div className={classloginUser}>
					{user ? (
						<>
							<div className={classes.flexIcon}>
								<FontAwesomeIcon icon={faUser} className={classes.icon} />
								<p>{user.name}</p>
								<FontAwesomeIcon
									icon={faSortDown}
									className={classes.iconDown}
								/>
								<NavLink
									to='sign-in'
									className={({ isActive }) =>
										isActive ? classes.active : classes.nav
									}
								>
									<p className={classes.textLogout} onClick={handlerLogout}>
										Logout
									</p>
								</NavLink>
							</div>
						</>
					) : (
						<NavLink
							to='login'
							className={({ isActive }) =>
								isActive ? classes.active : classes.nav
							}
						>
							<div className={classes.flexIcon}>
								<FontAwesomeIcon icon={faUser} className={classes.icon} />
								<p>Login</p>
							</div>
						</NavLink>
					)}
				</div>
			</div>
		</div>
	);
};

export default memo(Navbar);
