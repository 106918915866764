import React, { useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './SigninPage.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginAction } from '../store/loginUserSlice';

const cx = classNames.bind(styles);

const SigninPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [error, setError] = useState('');
	const passRef = useRef();
	const emailRef = useRef();
	// sử lý input data và validate
	const handlerSignIn = () => {
		const emailValue = emailRef.current.value.trim();
		const passValue = passRef.current.value.trim();
		const storeUsers = JSON.parse(localStorage.getItem('userArr')) || [];
		const user = storeUsers.find(
			(item) => item.email === emailValue && item.password === passValue
		);
		if (user) {
			dispatch(loginAction.onLogin(user));
			localStorage.setItem('loggedInUser', JSON.stringify(user));
			navigate('/');
		}
		let newError = {};

		if (!emailValue) {
			newError.emailValue = 'Please enter your email';
		}

		if (!user) {
			newError.error = 'Email or password is not correct';
			passRef.current.value = '';
		}

		setError(newError);
		return Object.keys(newError).length === 0;
	};

	return (
		<>
			<div className={cx('container')}>
				<div className={cx('container-form')}>
					<h3>Sign In</h3>
					<div className={cx('form')}>
						<input ref={emailRef} placeholder='Email' type='email' />
						{error.emailValue && (
							<p style={{ color: 'red' }}>{error.emailValue}</p>
						)}
						<br />
						<input ref={passRef} placeholder='Password' />

						{error.error && <p style={{ color: 'red' }}>{error.error}</p>}
						{/* {error.passValue && (
							<p style={{ color: 'red' }}>{error.passValue}</p>
						)} */}
						<button onClick={handlerSignIn}>Sign IN</button>
						<p>
							Create an account? <Link to='/login'>Click</Link>
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default SigninPage;
