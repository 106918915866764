import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './/CheckOut.module.scss';
import { useSelector } from 'react-redux';

const cx = classNames.bind(styles);
const CheckOut = () => {
	const [cartItems, setCartItems] = useState([]);
	const productcart = useSelector((state) => state.product.productCart);
	const products = useSelector((state) => state.product.products);
	console.log('products', productcart);

	useEffect(() => {
		const storeItem = JSON.parse(localStorage.getItem('cartItems')) || [];
		setCartItems(storeItem);
	}, [productcart]);
	let totalProduct = 0;
	let total = 0;
	return (
		<>
			<div className={cx('container')}>
				<div className={cx('banner-shop')}>
					<p className={cx('checkout-text')}>CHECKOUT</p>
					<div className={cx('checkout-link')}>
						<p className={cx('checkout-home-cart')}>HOME /</p>
						<p className={cx('checkout-home-cart')}> CART /</p>
						<p className={cx('checkout')}> CHECKOUT</p>
					</div>
				</div>
				<div className={cx('container-form')}>
					<h4>BILLING DETAILS</h4>
					<div className={cx('flex-item')}>
						<div className={cx('form-input')}>
							<form>
								<label>
									<p>FULL NAME:</p>
									<input
										placeholder='Enter Your FullName To Here !'
										type='name'
									/>
								</label>
								<br />
								<label>
									<p>EMAIL:</p>
									<input
										placeholder='Enter Your FullName To Here !'
										type='email'
									/>
								</label>
								<br />

								<label>
									<p>NUMBER PHONE:</p>

									<input
										placeholder='Enter Your FullName To Here !'
										type='tel'
									/>
								</label>
								<br />

								<label>
									<p>ADDRESS:</p>
									<input placeholder='Enter Your FullName To Here !' />
								</label>
								<button>Place Order</button>
							</form>
						</div>
						<div className={cx('your-order')}>
							<h4>YOUR ORDER </h4>
							{cartItems.map((item, index) => {
								total = item.product.price * item.quantity;
								totalProduct += total;
								return (
									<>
										<div className={cx('total-price')} key={index}>
											<p className={cx('name')}>{item.product.name}</p>
											<p className={cx('price')}>
												{item.product.price
													.toString()
													.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}{' '}
												VND x {item.quantity}
											</p>
										</div>
									</>
								);
							})}
							<div className={cx('total-product')}>
								<p>TOTAL</p>
								<p className={cx('total-price-product')}>
									{totalProduct
										.toString()
										.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}{' '}
									VND
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CheckOut;
